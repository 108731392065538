import React from "react";
import { stackList } from "../../data/ProjectData";
import {
  Image,
  Technologies,
  Tech,
  TechImg,
  TechName,
  ContactWrapper,
} from "./AboutElements";
import ScrollAnimation from "react-animate-on-scroll";
function About() {
  return (
    <ContactWrapper id="about">
      <div className="Container">
        <div className="SectionTitle">About Me</div>
        <div className="BigCard">
    
          <div className="AboutBio">
            Hello! My name is <strong>Demetris Perdikos</strong>. 

            <br />
            
            I am a passionate and hardworking software engineer specializing in front-end development and data analysis. With three years of hands-on experience in software development, coupled with a Bachelor's degree in Computer Science from the New Jersey Institute of Technology, I have honed my skills in developing innovative full-stack applications for both mobile and web platforms. My academic and real-world experiences have enabled me to explore and master a variety of software frameworks and programming languages, making me a versatile and proficient software engineer.
            

            <br />

            My portfolio showcases a range of projects that demonstrate my ability to develop a wide range of applications using different technologies with creativity and precision. Looking towards the future, I am eager to contribute my skills and knowledge to new opportunities that challenge me and allow for personal and professional growth.            <div className="tagline2">
               My current skillset includes:
              </div>
            

            <Technologies>
              {stackList.map((stack, index) => (
                <ScrollAnimation animateIn="fadeInLeft" key={index}>
                  <Tech key={index} className="tech">
                    <TechImg src={stack.img} alt={stack.name} />
                    <TechName>{stack.name}</TechName>
                  </Tech>
                </ScrollAnimation>
              ))}
            </Technologies>
          </div>

        </div>
      </div>
    </ContactWrapper>
  );
}

export default About;

export const ProjectList = [
  {
    img: "/palettePick.mp4", 
    title: "Palette Combo",
    description:"Palette Combo is a web-based tool designed for designers and artists to explore and generate harmonious color schemes. Users can upload an image, pick colors directly from the image, and receive curated color combinations based on their selections. The app leverages color theory principles to suggest complementary, triad, and quad color schemes, enhancing the user's ability to create visually appealing designs. Whether you're working on a digital art piece, website design, or branding project, Palette Combo simplifies the process of finding the perfect color palette.",
    tech_stack: ["React", "CSS", "NodeJs", "CSS" ],
    github_url: "https://github.com/demetrisperdikos/PaletteCombo",
  },


  {
    img: "/carCare.mov", 
    title: "Car-Care",
    description:
      "An app for mechanics and car users to have a more stre amlined system of interaction. For users the users input details about their vehicle and the specific service they need, which prompts the app to provide a list of mechanics nearby, including their services provided, and ratings from previous customers. The users have a map on the top half and a list of services on the bottom. If they click on a service on the bottom then the map filters mechanics that offer that specific service. They can then click on a mechanic and it will prompt them with a box that shows their name, services, and rating. Then the user can request a quote with just a click of a button for that specific service they clicked on.",
    tech_stack: ["MongoDB", "React Native", "CSS", "NodeJs" ],
    github_url: "https://github.com/demetrisperdikos/Car-Care",
  },


  {
    img: "/PlayHoboken.MP4", 
    title: "Play Hoboken App",
    description:
      "The PlayHoboken app is an innovative social networking platform designed for enthusiasts of hobbies and games. This app, available on iOS, Android, and as a Progressive Web App (PWA), connects individuals by facilitating the management of friends and coordination of peer-to-peer activities. It aims to elevate user engagement and retention through a community-oriented approach.",
    tech_stack: ["Firebase", "React Native", "CSS", "NodeJs" ],
    github_url: "https://github.com/demetrisperdikos/PlayHobokenApp",
  },
  {
    img: "/fit.mp4",
    title: "Fit Fusion",
    description:
      "Served as lead developer and project manager for a React application, designed to track calories, monitor mental state, visualize progress, and connect users with local coaches through integrated messaging.",
      tech_stack: ["React", "CSS", "NodeJs", "SQL", "CSS" ],
      github_url: "https://github.com/demetrisperdikos/Fitfusion-Fitness-Application_FrontEnd",
  },
  {
    img: "/9-6.mp4",
    title: "9 to 6 Puzzle Game",
    description:
      "Developed a Flutter-based word game to boost brand visibility and drive an increase in-person foot traffic with a daily updating word game",
    tech_stack: ["Dart", "Flutter", "Firebase", "CSS", "Android Studio"],
    github_url: "https://github.com/demetrisperdikos/9-to-6-Puzzle-Game",
  },
  {
    img: "/calendar.mp4",
    title: "Multi-Functional Schedule Application",
    description:
      "a feature-rich calendar that integrates a large list of features such as scheduling, photo display,note-taking, customizable themes, event notifications, search function, and weather updates",
    tech_stack: ["React", "NodeJs", "CSS"],
    github_url: "https://github.com/demetrisperdikos/CalendarApp",
  },
 
];

// --------------------------------------- Skills

export const stackList = [
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original.svg",
    name: "HTML",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original.svg",
    name: "CSS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg",
    name: "JavaScript",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
    name: "ReactJS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/dart/dart-original.svg",
    name: "Dart",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/flutter/flutter-original.svg",
    name: "Flutter",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nextjs/nextjs-original.svg",
    name: "NextJs",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg",
    name: "Typescript",
  },
  
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/git/git-original.svg",
    name: "Git",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
    name: "React Native",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original.svg",
    name: "NodeJS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/firebase/firebase-plain.svg",
    name: "Firebase",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original.svg",
    name: "MongoDB",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
    name: "Python",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/c/c-original.svg",
    name: "C",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/java/java-original.svg",
    name: "Java",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original.svg",
    name: "MySQL",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/bootstrap/bootstrap-original.svg",
    name: "Bootstrap",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/figma/figma-original.svg",
    name: "Figma",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/vscode/vscode-original.svg",
    name: "VS Code",
  },
 
];
